import _ from "underscore";
export default {
    name: "CreatePlanStepTwo",
    data() {
        return {
            form: {
                project_name: '',
                billing_method: '',
                daily_budget: '',
                time_set: [],
                delivery_speed: 1,
                planType:1
            },
            radio: 3,
            radio2: 1,
            radio3: 1,
            radio4: 1,
            radio5: 0,
            drawer: false,
            drawer2: false,
            isTitle: false,
            customBudget: '',
            tableData: [{
                title: '时段',
                '06': '1',
                '612': '',
                '1218': '',
                '1824': '',
            }],
            oneToSix: [
                { "type": 0, "discount": 100, "launch": 1, 'timezone': '00:00-01:00', 'name': 'oneToSix', 'index': 0 },
                { "type": 1, "discount": 100, "launch": 1, 'timezone': '01:00-02:00', 'name': 'oneToSix', 'index': 1 },
                { "type": 2, "discount": 100, "launch": 1, 'timezone': '02:00-03:00', 'name': 'oneToSix', 'index': 2 },
                { "type": 3, "discount": 100, "launch": 1, 'timezone': '03:00-04:00', 'name': 'oneToSix', 'index': 3 },
                { "type": 4, "discount": 100, "launch": 1, 'timezone': '04:00-05:00', 'name': 'oneToSix', 'index': 4 },
                { "type": 5, "discount": 100, "launch": 1, 'timezone': '05:00-06:00', 'name': 'oneToSix', 'index': 5 },
            ],
            sixToTwelve: [
                { "type": 6, "discount": 100, "launch": 1, 'timezone': '06:00-07:00', 'name': 'sixToTwelve', 'index': 0 },
                { "type": 7, "discount": 100, "launch": 1, 'timezone': '07:00-08:00', 'name': 'sixToTwelve', 'index': 1 },
                { "type": 8, "discount": 100, "launch": 1, 'timezone': '08:00-09:00', 'name': 'sixToTwelve', 'index': 2 },
                { "type": 9, "discount": 100, "launch": 1, 'timezone': '09:00-10:00', 'name': 'sixToTwelve', 'index': 3 },
                { "type": 10, "discount": 100, "launch": 1, 'timezone': '10:00-11:00', 'name': 'sixToTwelve', 'index': 4 },
                { "type": 11, "discount": 100, "launch": 1, 'timezone': '11:00-12:00', 'name': 'sixToTwelve', 'index': 5 },
            ],
            TwelveToEighteen: [
                { "type": 12, "discount": 100, "launch": 1, 'timezone': '12:00-13:00', 'name': 'TwelveToEighteen', 'index': 0 },
                { "type": 13, "discount": 100, "launch": 1, 'timezone': '13:00-14:00', 'name': 'TwelveToEighteen', 'index': 1 },
                { "type": 14, "discount": 100, "launch": 1, 'timezone': '14:00-15:00', 'name': 'TwelveToEighteen', 'index': 2 },
                { "type": 15, "discount": 100, "launch": 1, 'timezone': '15:00-16:00', 'name': 'TwelveToEighteen', 'index': 3 },
                { "type": 16, "discount": 100, "launch": 1, 'timezone': '16:00-17:00', 'name': 'TwelveToEighteen', 'index': 4 },
                { "type": 17, "discount": 100, "launch": 1, 'timezone': '17:00-18:00', 'name': 'TwelveToEighteen', 'index': 5 },
            ],
            EighteenToTwentyFour: [
                { "type": 18, "discount": 100, "launch": 1, 'timezone': '18:00-19:00', 'name': 'EighteenToTwentyFour', 'index': 0 },
                { "type": 19, "discount": 100, "launch": 1, 'timezone': '19:00-20:00', 'name': 'EighteenToTwentyFour', 'index': 1 },
                { "type": 20, "discount": 100, "launch": 1, 'timezone': '20:00-21:00', 'name': 'EighteenToTwentyFour', 'index': 2 },
                { "type": 21, "discount": 100, "launch": 1, 'timezone': '21:00-22:00', 'name': 'EighteenToTwentyFour', 'index': 3 },
                { "type": 22, "discount": 100, "launch": 1, 'timezone': '22:00-23:00', 'name': 'EighteenToTwentyFour', 'index': 4 },
                { "type": 23, "discount": 100, "launch": 1, 'timezone': '23:00-24:00', 'name': 'EighteenToTwentyFour', 'index': 5 }
            ],
            visible: false,
            hoverFormData: {
                show: false,
                type: null,
                discount: null,
                launch: null,
                timezone: null,
                name: null,
                index: null,
                style: ''
            },
            clickFormData: {
                show: false,
                discount: null,
                type: null,
                launch: null,
                timezone: null,
                name: null,
                index: null,
                style: ''
            },
            isDisabled: false,
            timeZoneSet: [],
            projectNameStatus: false,
            tmpProjectName: null,
            createdPlanData:[],
            selectCreateData:[
            ],
            selctedValue:''
        }
    },
    created() {
        if (this.$route.query.id) {
            let param = {
                id: this.$route.query.id
            }
            this.$http.axiosGetBy(this.$api.ep_getepd, param, (res) => {
                if (res.code === 200) {
                    this.form.planType=res.data.type
                    let form_data = res.data;
                    this.form.project_name = form_data.project_name;
                    this.tmpProjectName = form_data.project_name;
                    if (Number(form_data.daily_budget)) {
                        this.radio2 = 2;
                        this.form.daily_budget = form_data.daily_budget;
                    }
                    this.form.delivery_speed = form_data.put_tactics;
                    if (form_data.time_set.length) {
                        if (form_data.time_set.length === 24) {
                            this.radio4 = 1;
                        } else {
                            this.radio4 = 2;
                        }
                        this.oneToSix = _.map(this.oneToSix, (item, index) => {
                            let tmp = _.find(form_data.time_set, { time: item.type + 1 });
                            if (tmp) {
                                item.discount = tmp.discount;
                                item.launch = 1;
                            } else {
                                item.discount = 0;
                                item.launch = 0;
                            }
                            return item;
                        });
                        this.sixToTwelve = _.map(this.sixToTwelve, (item) => {
                            let tmp = _.find(form_data.time_set, { time: item.type + 1 });
                            if (tmp) {
                                item.discount = tmp.discount;
                                item.launch = 1;
                            } else {
                                item.discount = 0;
                                item.launch = 0;
                            }
                            return item;
                        });
                        this.TwelveToEighteen = _.map(this.TwelveToEighteen, (item) => {
                            let tmp = _.find(form_data.time_set, { time: item.type + 1 });
                            if (tmp) {
                                item.discount = tmp.discount;
                                item.launch = 1;
                            } else {
                                item.discount = 0;
                                item.launch = 0;
                            }
                            return item;
                        });
                        this.EighteenToTwentyFour = _.map(this.EighteenToTwentyFour, (item) => {
                            let tmp = _.find(form_data.time_set, { time: item.type + 1 });
                            if (tmp) {
                                item.discount = tmp.discount;
                                item.launch = 1;
                            } else {
                                item.discount = 0;
                                item.launch = 0;
                            }
                            return item;
                        });
                        this.closeDrawer2();
                    }
                } else {
                    this.$message.warning(res.msg)
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        }
        this.initTimeZone();
        if(localStorage.getItem('promotionPlanList')){
            this.createdPlanData=JSON.parse(localStorage.getItem('promotionPlanList'))
            this.createdPlanData.forEach(item => {
                let obj={
                    label:item.project_name,
                    value:item.id
                }
                this.selectCreateData.push(obj)
                
            });
        }
    },
    methods: {
        changeDiscount (val) {
            if (Number(val) === 2) {
                this.clickFormData.discount = 100;
            }
        },
        changeDelivery(val) {
            if (Number(this.radio2) === 1 && Number(val) === 0) {
                this.isTitle = true;
            } else {
                this.isTitle = false;
            }
        },
        initTimeZone() {
            if (!this.form.time_set.length && !this.$route.query.id) {
                for (let i = 0; i < 24; i++) {
                    this.timeZoneSet[i] = {
                        discount: 100,
                        timezone: i < 10 ? `0${i}:00-0${i+1}:00` : `${i}:00-${i+1}:00`,
                        index: i
                    }
                }
            }
        },
        confirmBudget() {
            if (!this.form.daily_budget) {
                this.$message.warning('自定义预算不能为零')
                return false;
            }
            if (this.form.daily_budget > parseFloat(this.$lockr.get('amount'))) {
                this.$message.warning('自定义预算不能大于系统给的推广资金');
                return;
            }
            if (this.form.daily_budget < 50) {
                this.$message.warning('自定义预算不能小于50');
                return;
            }
            if (!this.validatePlanCost(this.form.daily_budget)) {
                return;
            }
            if (this.isTitle) {
                this.isTitle = false;
            }
            this.drawer = false;
        },
        validatePlanCost (daily_bugget) {
            let plan_id = this.$route.query.id ? Number(this.$route.query.id) : 0;
            let plan_total_cost = this.$lockr.get('plan_total_cost');
            let total = 0;
            let amount = this.$lockr.get('amount');
            if (plan_id) {
                _.each(plan_total_cost, (item) => {
                    if (Number(plan_id) === item.plan_id) {
                        total += Number(daily_bugget);
                    } else {
                        total += Number(item.daily_budget);
                    }
                });
            } else {
                _.each(plan_total_cost, (item) => {
                    total += Number(item.daily_budget);
                });
                total += Number(daily_bugget);
            }
            if (total > Number(amount)) {
                this.$message({
                    type: 'warning',
                    message: '计划日预算总和不能超过系统设定的推广余额',
                    duration: 2000
                });
                return false;
            } else {
                return true;
            }

        },
        setTime(name, index, discount) {
            if ((discount < 30 && this.radio5 === 1 ) || (discount > 500 && this.radio5 === 1)) {
                this.$message.warning('折扣不能小于30或者大于500');
                return;
            }
            if (name === 'oneToSix') {
                if (Number(this.radio5) === 1) {
                    if (discount < 1) {
                        this.$message.warning('折扣不能设置为0！');
                        return
                    }
                    this.oneToSix[index].discount = discount;
                    this.oneToSix[index].launch = 1
                } else if (Number(this.radio5) === 2) {
                    this.oneToSix[index].launch = 1
                    this.oneToSix[index].discount = 100;
                } else if (Number(this.radio5) === 3) {
                    this.oneToSix[index].launch = 0;
                    this.oneToSix[index].discount = 0;
                }
            } else if (name === 'sixToTwelve') {
                if (Number(this.radio5) === 1) {
                    if (discount < 1) {
                        this.$message.warning('折扣不能设置为0！');
                        return
                    }
                    this.sixToTwelve[index].discount = discount;
                    this.sixToTwelve[index].launch = 1
                } else if (this.radio5 === 2) {
                    this.sixToTwelve[index].launch = 1
                    this.sixToTwelve[index].discount = 100;
                } else if (this.radio5 === 3) {
                    this.sixToTwelve[index].launch = 0;
                    this.sixToTwelve[index].discount = 0;
                }
            } else if (name === 'TwelveToEighteen') {
                if (Number(this.radio5) === 1) {
                    if (discount < 1) {
                        this.$message.warning('折扣不能设置为0！');
                        return
                    }
                    this.TwelveToEighteen[index].discount = discount;
                    this.TwelveToEighteen[index].launch = 1
                } else if (Number(this.radio5) === 2) {
                    this.TwelveToEighteen[index].launch = 1
                    this.TwelveToEighteen[index].discount = 100;
                } else if (Number(this.radio5) === 3) {
                    this.TwelveToEighteen[index].launch = 0;
                    this.TwelveToEighteen[index].discount = 0;
                }
            } else if (name === 'EighteenToTwentyFour') {
                if (Number(this.radio5) === 1) {
                    if (discount < 1) {
                        this.$message.warning('折扣不能设置为0！');
                        return
                    }
                    this.EighteenToTwentyFour[index].discount = discount;
                    this.EighteenToTwentyFour[index].launch = 1
                } else if (Number(this.radio5) === 2) {
                    this.EighteenToTwentyFour[index].launch = 1
                    this.EighteenToTwentyFour[index].discount = 100;
                } else if (Number(this.radio5) === 3) {
                    this.EighteenToTwentyFour[index].launch = 0;
                    this.EighteenToTwentyFour[index].discount = 0;
                }
            }
            let isAllTime = true;
            _.each(this.oneToSix, (item) => {
                if (item.launch === 0) {
                    isAllTime = false;
                }
            });
            _.each(this.sixToTwelve, (item) => {
                if (item.launch === 0) {
                    isAllTime = false;
                }
            });
            _.each(this.TwelveToEighteen, (item) => {
                if (item.launch === 0) {
                    isAllTime = false;
                }
            });
            _.each(this.EighteenToTwentyFour, (item) => {
                if (item.launch === 0) {
                    isAllTime = false;
                }
            });
            this.radio4 = isAllTime ? 1 : 2;
            this.clickFormData.show = false;
        },
        selectBudget(val) {
            if (Number(val) === 1) {
                this.form.daily_budget = 0;
            }
            if (Number(this.form.delivery_speed) === 0 && Number(val) === 1) {
                this.isTitle = true;
            } else {
                this.isTitle = false;
            }
            if (val === 2) {
                this.drawer = true;
            }
        },
        showBudget() {
            this.drawer = true;
        },
        toBack() {
            this.$router.push({
                path: '/student/operationpromotion/searchads/createplanstepone'
            })
        },
        clickDialog(item, evt) {
            if (item.launch === 0 || Number(item.discount) === 0) {
                this.radio5 = 3;
                item.launch = 0;
            } else {
                if (item.discount != 100) {
                    this.radio5 = 1
                } else {
                    this.radio5 = 2;
                }
            }
            let winClientWidth = document.body.clientWidth;
            this.closeHoverD();
            this.clickFormData.timezone = item.timezone;
            this.clickFormData.name = item.name;
            this.clickFormData.index = item.index;
            this.clickFormData.launch = item.launch;
            this.clickFormData.discount = item.discount;
            this.$set(this.clickFormData, 'show', true);
            this.clickFormData.show = true;
            let x = evt.clientX + 20;
            let y = evt.clientY + 20;
            if (winClientWidth - 246 < x) {
                this.clickFormData.style = {
                    left: `${winClientWidth - 246}px`,
                    top: `${y}px`
                }
            } else {
                this.clickFormData.style = {
                    left: `${x}px`,
                    top: `${y}px`
                }
            }
        },
        hoverDialog(item, evt) {
            let winClientWidth = document.body.clientWidth;
            this.hoverFormData.timezone = item.timezone;
            this.hoverFormData.discount = item.discount;
            this.hoverFormData.show = true;
            let x = evt.clientX + 4;
            let y = evt.clientY - 100;
            if (winClientWidth - 210 < x) {
                this.hoverFormData.style = {
                    left: `${winClientWidth - 210 }px`,
                    top: `${y}px`
                }
            } else {
                this.hoverFormData.style = {
                    left: `${x}px`,
                    top: `${y}px`
                }
            }
        },
        closeHoverD() {
            this.hoverFormData.show = false;
        },
        closeDrawer2() {
            this.timeZoneSet = [];
            _.each(this.oneToSix, (item, index) => {
                if (item.launch) {
                    let tmp = {
                        discount: item.discount,
                        time: item.timezone,
                        index
                    }
                    this.timeZoneSet[index] = tmp;
                }
            });

            _.each(this.sixToTwelve, (item, index) => {
                let sixIndex = 6;
                sixIndex += index;
                if (item.launch) {
                    let tmp = {
                        discount: item.discount,
                        time: item.timezone,
                        index: sixIndex
                    }
                    this.timeZoneSet[sixIndex] = tmp;
                }
            });

            _.each(this.TwelveToEighteen, (item, index) => {
                let twelveIndex = 12;
                twelveIndex += index;
                if (item.launch) {
                    let tmp = {
                        discount: item.discount,
                        time: item.timezone,
                        index: twelveIndex
                    }
                    this.timeZoneSet[twelveIndex] = tmp;
                }
            })

            _.each(this.EighteenToTwentyFour, (item, index) => {
                let eighteenIndex = 18;
                eighteenIndex += index;
                if (item.launch) {
                    let tmp = {
                        discount: item.discount,
                        time: item.timezone,
                        index: eighteenIndex
                    }
                    this.timeZoneSet[eighteenIndex] = tmp;
                }
            });
            this.clickFormData.show = false;
            this.drawer2 = false;
        },
        selectShowWay(val) {
            this.radio4 = val
            if (Number(val) === 2) {
                this.oneToSix = _.map(this.oneToSix, (item) => {
                    item.launch = 0;
                    item.discount = 0;
                    return item;
                });
                this.sixToTwelve = _.map(this.sixToTwelve, (item) => {
                    item.launch = 0;
                    item.discount = 0;
                    return item;
                });
                this.TwelveToEighteen = _.map(this.TwelveToEighteen, (item) => {
                    item.launch = 0;
                    item.discount = 0;
                    return item;
                });
                this.EighteenToTwentyFour = _.map(this.EighteenToTwentyFour, (item) => {
                    item.launch = 0;
                    item.discount = 0;
                    return item;
                });
            } else {
                this.oneToSix = _.map(this.oneToSix, (item) => {
                    item.launch = 1;
                    item.discount = 100;
                    return item;
                });
                this.sixToTwelve = _.map(this.sixToTwelve, (item) => {
                    item.launch = 1;
                    item.discount = 100;
                    return item;
                });
                this.TwelveToEighteen = _.map(this.TwelveToEighteen, (item) => {
                    item.launch = 1;
                    item.discount = 100;
                    return item;
                });
                this.EighteenToTwentyFour = _.map(this.EighteenToTwentyFour, (item) => {
                    item.launch = 1;
                    item.discount = 100;
                    return item;
                });
            }

        },
        toNext() {
            //设置了日预算的话，要对日预算进行验证
            if (this.form.daily_budget) {
                if (!this.validatePlanCost(this.form.daily_budget)) {
                    return;
                }
            }
            new Promise((resolve, reject) => {
                if (this.form.project_name === this.tmpProjectName) {
                    resolve({code: 200, data: {is_repeat: false}, msg: ''});
                } else {
                    this.$http.axiosGetBy(this.$api.SDcheckPN, { project_name: this.form.project_name }, (res) => {
                        resolve(res);
                    }, (err) => {
                        reject(err);
                    });
                }

            }).then(res => {
                if (res.code === 200) {
                    // if (!res.data.is_repeat) {
                        if (this.isTitle) {
                            return;
                        }
                        if (!this.form.project_name || this.form.project_name === '') {
                            this.$message.warning('请输入计划名称');
                            return
                        } else if (this.radio2 === 2) {
                            if (this.form.daily_budget < 50 || this.form.daily_budget > 9999999) {
                                this.$message.warning('自定义预算范围为50~9999999');
                                return;
                            }
                        }
                        this.isTitle = false;
                        let budget = [];
                        this.oneToSix.map(item => {
                            budget.push(item)
                        })
                        this.sixToTwelve.map(item => {
                            budget.push(item)
                        })
                        this.TwelveToEighteen.map(item => {
                            budget.push(item)
                        })
                        this.EighteenToTwentyFour.map(item => {
                            budget.push(item)
                        })
                        let list = [];
                        budget.map(item => {
                            if (item.launch === 1) {
                                list.push(item)
                            }
                        })
                        if (list.length === 0) {
                            this.$message.warning('时段设置至少设置一个！');
                            return;
                        }
                        this.form.time_set = this.timeZoneSet;
                        if (this.radio2 === 1) {
                            this.form.daily_budget = 0;
                        }
                        this.$lockr.set('form', this.form);
                        this.$lockr.set('planTwo',{search_budget:this.form.daily_budget})
                        if (this.$route.query.id) {
                            this.$router.push({
                                path: '/student/operationpromotion/searchads/createplanstepthree',
                                query: {
                                    id: this.$route.query.id
                                }
                            })
                        } else {
                            this.$router.push({
                                path: '/student/operationpromotion/searchads/createplanstepthree'
                            })
                        }
                    // } 
                    // else {
                    //     this.$message.warning('当前的计划名称已被使用，请更换其他的计划名称！');
                    //     return;
                    // }
                } else {
                    this.$message.warning(res.msg);
                    return;
                }
            }).catch(err => {
                this.$common.axiosErrorMsg(err);
            });
        },
        selected(e){
            console.log(e,this.selectCreateData,"000");
            let item=this.createdPlanData.find(data=>data.id==e)
            console.log(item,"pppp");
            this.form.project_name=item.project_name
            // this.radio2=

        },
        selectedPlan(){
            this.$refs.selectDropdown.toggleMenu();
        }
    }
}